import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  DeleteBill,
  getBill,
  getBillFileUrl,
} from "../../services/Currency.service";
import "./OutflowBill.css";
import OutflowSplitbill from "../OutflowSplitbill/OutflowSplitbill";
import Spinner from "../../components/Spinner/Spinner";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
} from "@mui/material";
import OutflowForm from "../OutflowForm/OutflowForm";
import Close from "@mui/icons-material/Close";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";

function OutflowFormDialog(props) {
  const { onClose, open, callBillApi, setCallBillApi } = props;

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} maxWidth={"lg"}>
      <DialogTitle>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <div>Enter Bill</div>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </div>
      </DialogTitle>
      <OutflowForm handleClose={handleClose} callBillApi={callBillApi} setCallBillApi={setCallBillApi} />
    </Dialog>
  );
}

const OutflowBill = ({ user }) => {
  const [bill, setBill] = useState([]);
  const [splitButtonVisible, setSplitButtonVisible] = useState(true);
  const [selectedSplit, setSelectedSplit] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [callBillApi, setCallBillApi] = useState(false)

  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (value) => {
    setOpen(false);
  };

  const handleGetBillFile = async (bill) => {
    const url = await getBillFileUrl(bill.id);
    if (url) window.open(url, "_blank");
  };

  const handleSplitFlag = (idx) => {
    // console.log("splitButton", idx);
    setSplitButtonVisible(false);
    setSelectedSplit(idx);
  };

  const fetchBill = async () => {
    // let bills = await getBill();
    // setBill(bills.palyload);

    setIsLoading(true);
    let bills = await getBill();
    setBill(bills.palyload);
    // console.log('bills', bill)
    setIsLoading(false);
  };
  useEffect(() => {
    fetchBill();
  }, [callBillApi]);

  const handledeleteInvoice = async (id) => {
    const result = await DeleteBill(id);
    setApiMsg(result?.message)
    setOpenSnackbar(true)
    fetchBill();
    setTimeout(() => { setOpenSnackbar(false) }, [1500])
  };
  // console.log("invoke button", splitButtonVisible);

  const isBillStatusC = bill?.filter((item) => item?.status === "C")

  return (
    <div>
      <div className="outflowbill-button-searchbox">
        <Button
          sx={{ fontSize: "0.6rem" }}
          variant="contained"
          size="small"
          onClick={handleClickOpen}
        >
          Enter Bill
        </Button>
      </div>

      <OutflowFormDialog open={open} onClose={handleClose} callBillApi={callBillApi} setCallBillApi={setCallBillApi} />

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {isBillStatusC?.length > 0 ? isBillStatusC?.map((element, index) => {
            const backgroundColor = index % 2 === 0 ? '#F8F8F8' : 'white'
            return (
              <Paper key={index} className="outflowbill-container-box" style={{ backgroundColor }}>
                <div className="table-details-bill">
                  <div className="table-details-bill-left">
                    <div
                      onClick={() => handleGetBillFile(element)}
                      className="outflowbill-invoice"
                    >
                      <span className="pdfLogo-img">
                        <img
                          src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                          alt=""
                        />
                      </span>
                      <p className="outflow-invoice-number">
                        {element.invoice_no}
                      </p>
                    </div>
                  </div>
                  <div className="table-details-bill-right">
                    <div className="field-col">
                      <div className="field">
                        <p className="tags">From : </p>
                        <p className="tags1">{element.vendor.name}</p>
                      </div>
                      <div className="field">
                        <p className="tags">Total :</p>
                        <p className="tags1">{element.currency}$</p>
                        <p className="tags1">{element.total_amt}</p>
                      </div>
                    </div>
                    <div className="field-col">
                      <div className="field">
                        <p className="tags">PO Number :</p>
                        <p className="tags1">{element.po_no}</p>
                      </div>
                      <div className="field">
                        <p className="tags">To :</p>
                        <p className="tags1">{element.organisation.name}</p>
                      </div>
                    </div>
                    <div className="field-col">
                      <div className="field">
                        <p className="tags">Sub-Total :</p>
                        <p className="tags1">{element.currency}$</p>
                        <p className="tags1">{element.subtotal}</p>
                      </div>
                      <div className="field">
                        <p className="tags">PO Number :</p>
                        <p className="tags1">{element.po_no}</p>
                      </div>
                    </div>
                    <div className="field-col">
                      <div className="field">
                        <p className="tags">GST :</p>
                        <p className="tags1">{element.currency}</p>
                        <p className="tags1">${element.total_gst}</p>
                      </div>
                    </div>
                  </div>
                </div>

                {splitButtonVisible || selectedSplit !== index ? (
                  <div className="outflowbill-main-button-box">
                    <div>
                      <Button
                        /* className="outflowbill-3button" */ sx={{
                          fontSize: "0.6rem",
                        }}
                        size="small"
                        variant="outlined"
                        onClick={() => handledeleteInvoice(element.id)}
                      >
                        Delete
                      </Button>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        sx={{ fontSize: "0.6rem" }}
                        size="small"
                        onClick={() => handleSplitFlag(index)}
                      // className="outflowbill-3button"
                      >
                        Split
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div>
                    <OutflowSplitbill
                      invoice_id={element.id}
                      amount={element.subtotal}
                      GL_code={element.outflowbill_products[0].gl_code}
                      currency={element.currency}
                      handleSplitFlag={handleSplitFlag}
                    />
                  </div>
                )}
              </Paper>
            );
          }) : <div style={{ textAlign: 'center', fontWeight: "500" }}>No bill available</div>}
        </>
      )}
      <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={"green"} />
    </div>
  );
};

export default OutflowBill;



{/* <div className="right-field">
                      <div className="field">
                        <p className="tags">From : </p>
                        <p className="tags1">{element.vendor.name}</p>
                      </div>

                      <div className="field">
                        <p className="tags">PO Number :</p>
                        <p className="tags1">{element.po_no}</p>
                      </div>

                      <div className="field">
                        <p className="tags">Sub-Total :</p>
                        <p className="tags1">{element.currency}$</p>
                        <p className="tags1">{element.subtotal}</p>
                      </div>

                      <div className="field">
                        <p className="tags">GST :</p>
                        <p className="tags1">{element.currency}</p>
                        <p className="tags1">${element.total_gst}</p>
                      </div>
                    </div>
                    <div className="right-field">
                      <div className="field">
                        <p className="tags">Total :</p>
                        <p className="tags1">{element.currency}$</p>
                        <p className="tags1">{element.total_amt}</p>
                      </div>

                      <div className="field">
                        <p className="tags">To :</p>
                        <p className="tags1">{element.organisation.name}</p>
                      </div>
                      <div className="field">
                        <p className="tags">PO Amount :</p>
                        <p className="tags1">${element.po_amount}</p>
                      </div>
                    </div> */}

