import React, { useContext, useState } from "react";
import "./RefusalPage.css";
import { useLocation } from "react-router-dom";
import {
  DirectorRefusesplitBill,
} from "../../services/Currency.service";
import { Button } from "@mui/material";
import GlobalContext from "../TimesheetContext/GlobalContext";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";

const RefusalPage = (props) => {
  const [note, setNote] = useState("");
  const [isSubmit, setIsSubmit] = useState(false)
  const { refusedObjData, handleClose } = props;
  const { dirid, del_splitbill_id, isRejected } = refusedObjData;
  const { setValue } = useContext(GlobalContext);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiMsg, setApiMsg] = useState(null);
  console.log("refusedObjData", refusedObjData)

  const handleChange = (event) => {
    setNote(event.target.value);
  };

  const handleSave = async (event) => {
    setIsSubmit(true)
    event.preventDefault();
    // const dirid = location.state.dirid;
    // const splitbill_id = location.state.del_splitbill_id;
    // const isRejected = location.state.isRejected;
    // await RefusesplitBill(splitbill_id, dirid, note);
    // navigate("/home/outflowbill");

    // await DirectorRefusesplitBill(splitbill_id, dirid, note, isRejected);
    const result = await DirectorRefusesplitBill(del_splitbill_id, dirid, note, isRejected);
    if (result) {
      setOpenSnackbar(true);
      setApiMsg(result?.message)
      setIsSubmit(false)
    }
    setTimeout(() => { setOpenSnackbar(false) }, 1500);
    setTimeout(() => { handleClose(); }, 1800);
    setTimeout(() => { setValue(2); }, 2000);
    // navigate("/home/directorrefusedinvoices");
  };

  const handleCancel = () => {
    handleClose()
  };
  return (
    <><div className="Refusal-page-container">
      <div className="Refusal-page-center-div">
        <textarea
          type="text"
          placeholder="Please enter reason for refusal!!!"
          value={note}
          onChange={handleChange} />
      </div>
      <div className="Refusal-page-right-div">
        <Button size="small" variant="outlined" sx={{ fontSize: "0.6rem" }} onClick={handleCancel}>
          Cancel
        </Button>
        <LoadingButton size="small" variant="contained" loading={isSubmit} loadingPosition="end" sx={{ fontSize: "0.6rem" }} onClick={handleSave}>
          Save
        </LoadingButton>
      </div>
    </div>
      <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={"green"} />
    </>
  );
};

export default RefusalPage;
