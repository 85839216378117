import React, { useEffect, useState } from "react";
import { GetUsers, getSplitbillData } from "../../services/Currency.service";
import "./DirectorbillApproved.css";
import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import Spinner from "../../components/Spinner/Spinner";

const DirectorbillApproved = ({ user }) => {
  const [splitbill, setSplitbill] = useState([]);
  const [users, setUsers] = useState([]);
  const [cuAllCom, setCUAllCom] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const tabelCellStyled = {
    tabelCell: {
      fontWeight: 500
    }
  }

  const fetchsplitBill = async () => {
    setIsLoading(true);
    let bills = await getSplitbillData();
    setSplitbill(bills);
    setIsLoading(false);
  };
  useEffect(() => {
    console.log("users approved", users);
    fetchsplitBill().then(() => getCurrentUsersCompany());
  }, [users]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    setUsers(await GetUsers());
  };

  const getCurrentUsersCompany = () => {
    let [currentUserAllCompanies = []] = users
      .filter((e) => e.email_id === user?.email)
      .map((e) => e?.organisations.map((e) => e.name));

    setCUAllCom([...currentUserAllCompanies]);
  };

  const isAllDA = (orgSplit) => {
    let flag = false;
    for (let i = 0; i < orgSplit.length; i++) {
      // console.log(orgSplit[i].dir_approval_status);
      if (orgSplit[i].dir_approval_status === "DA") {
        flag = true;
      } else {
        flag = false;
        break;
      }
    }
    return flag;
  };

  const handleView = (orgSplit) => {
    let flag = false;

    if (isAllDA(orgSplit)) {
      flag = true;
      return flag;
    }

    for (let i = 0; i < orgSplit.length; i++) {
      if (
        orgSplit[i].dir_approval_status === "DA" &&
        cuAllCom.includes(orgSplit[i].organisation.name)
      ) {
        flag = true;
        break;
      }
    }

    return flag;
  };

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {splitbill.map((element, index) => {
            return handleView(element.org_splits) ? (
              <Card key={index} sx={{ padding: "10px", margin:"10px 0" }}>
                <div className="dir_bill_apprvd_data">
                  <div className="dir_bill_apprvd_data_div1">
                    <p className="pdfLogo-img">
                      <img
                        src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                        alt=""
                      />
                    </p>
                    <p className="outflow-invoice-number">
                      {element.bill.invoice_no}
                    </p>
                  </div>
                  <div className="dir_bill_apprvd_data_div">
                    <div className="dir_bill_apprvd_data_div_field">
                      <div className="field_div_data">
                        <p className="tags">From : </p>
                        <p className="tags1">{element.bill.vendor.name}</p>
                      </div>
                      <div className="field_div_data">
                        <p className="tags">Rule :</p>
                        <p className="tags1">{element.rule}</p>
                      </div>
                    </div>
                    <div className="dir_bill_apprvd_data_div_field">
                      <div className="field_div_data">
                        <p className="tags">To :</p>
                        <p className="tags1">{element.bill.organisation.name}</p>
                      </div>
                      <div className="field_div_data">
                        <p className="tags">GL Code :</p>
                        <p className="tags1">{element.gl_code_rec}</p>
                      </div>
                    </div>
                    <div className="dir_bill_apprvd_data_div_field">
                      <div className="field_div_data">
                        <p className="tags">Sub-Total :</p>
                        <p className="tags1">{element.bill.currency}</p>
                        <p className="tags1">${element.bill.subtotal}</p>
                      </div>
                      <div className="field_div_data">
                        <p className="tags">PO Amount :</p>
                        <p className="tags1">${element.bill.po_amount}</p>
                      </div>
                    </div>
                    <div className="dir_bill_apprvd_data_div_field">
                      <div className="field_div_data">
                        <p className="tags">Total :</p>
                        <p className="tags1">{element.bill.currency}</p>
                        <p className="tags1">${element.bill.total_amt}</p>
                      </div>
                      <div className="field_div_data">
                        <p className="tags">GST :</p>
                        <p className="tags1">{element.bill.currency}</p>
                        <p className="tags1">${element.bill.total_gst}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <TableContainer style={{ padding: "0 0 10px 0 ", width: "100%" }}>
                  <Table size="small" sx={{ '& .MuiTableCell-root': { fontSize: '10px', fontWeight: "600" } }}>
                    <TableHead>
                      <TableRow>
                        <TableCell>Invoice No.</TableCell>
                        <TableCell>Company</TableCell>
                        <TableCell>Description</TableCell>
                        <TableCell>Split%</TableCell>
                        <TableCell>GST</TableCell>
                        <TableCell>Total(Excluding GST.)</TableCell>
                        <TableCell>Local(NZD)</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {element.org_splits.map((orgSplit, idx) => {
                        return (
                          <TableRow key={idx}>
                            <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.split_invoice_no}</TableCell>
                            <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.organisation.name}</TableCell>
                            <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.description}</TableCell>
                            <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.split_per}%</TableCell>
                            {/* <td>
                              {orgSplit.gst ? <span>&#10003;</span> : <></>}
                            </td> */}

                            <TableCell style={tabelCellStyled.tabelCell} className="td-data">
                              {orgSplit.gst ? (
                                <span>&#10003;</span>
                              ) : (
                                // <>&#xd7;</>
                                <>&#88;</>
                              )}
                              &nbsp;&nbsp;&nbsp;
                              <span className="span-data">
                                {orgSplit.organisation.currency}
                                {orgSplit.gst_amount}
                              </span>
                            </TableCell>
                            <TableCell style={tabelCellStyled.tabelCell}>
                              {orgSplit.organisation.currency}
                              {orgSplit.payOrganizationAmount}
                            </TableCell>
                            <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.local_nzd}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="director-approved-button">Approved</div>
              </Card>
            ) : null;
          })}
        </>
      )}
    </div>
  );
};

export default DirectorbillApproved;

{/* <div className="row-flex">
                    <div className="outflowbill-invoice">
                      <p className="pdfLogo-img">
                        <img
                          src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                          alt=""
                        />
                      </p>
                      <p className="outflow-invoice-number">
                        {element.bill.invoice_no}
                      </p>
                    </div>

                    <div className="field">
                      <p className="tags">From : </p>
                      <p className="tags1">{element.bill.vendor.name}</p>
                    </div>

                    <div className="field">
                      <p className="tags">PO Number :</p>
                      <p className="tags1">{element.bill.po_no}</p>
                    </div>

                    <div className="field">
                      <p className="tags">Sub-Total :</p>
                      <p className="tags1">{element.bill.currency}</p>
                      <p className="tags1">${element.bill.subtotal}</p>
                    </div>

                    <div className="field">
                      <p className="tags">GST :</p>
                      <p className="tags1">{element.bill.currency}</p>
                      <p className="tags1">${element.bill.total_gst}</p>
                    </div>

                    <div className="field">
                      <p className="tags">Total :</p>
                      <p className="tags1">{element.bill.currency}</p>
                      <p className="tags1">${element.bill.total_amt}</p>
                    </div>
</div> */}
// <div className="row-flex1">
//   <div className="field">
//     <p className="tags">To :</p>
//     <p className="tags1">{element.bill.organisation.name}</p>
//   </div>
//   <div className="field">
//     <p className="tags">PO Amount :</p>
//     <p className="tags1">${element.bill.po_amount}</p>
//   </div>
//   <div className="field">
//     <p className="tags">GL Code :</p>
//     <p className="tags1">{element.gl_code_rec}</p>
//   </div>
//   <div className="field">
//     <p className="tags">Rule :</p>
//     <p className="tags1">{element.rule}</p>
//   </div>
// </div>
