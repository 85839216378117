import React, { useEffect, useState } from "react";
import ProjectPercent from "../ProjectPercent/ProjectPercent";
import { useNavigate } from "react-router-dom";
import "./ProjectForm.css";
import { AddnewProject } from "../../services/Currency.service";
import { fetchApi } from "../../services/api.service";
import ProjectStaff from "../ProjectStaff/ProjectStaff";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Button, Select } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";
import { glCodeList } from "../../Data/data";

const initvalues = {
  project_title: "",
  cost: "",
  description: "",
  category: "",
  gl_code: "",
  start_date: "",
  end_date: "",
  color: "",
  // project_org_id: "",
  project_percent: [],
  project_staff: [],
  "user_id": ''
};

const ProjectForm = ({ handleClose, user }) => {
  initvalues.user_id = user;
  const [addProject, setaddProject] = useState(initvalues);
  const [orgs, setOrgs] = useState([]);
  const [isSubmit, setIsSubmit] = React.useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null);

  // console.log("user-data", user);
  const navigate = useNavigate();
  const {
    project_title,
    cost,
    description,
    category,
    gl_code,
    start_date,
    end_date,
    color,

    // project_org_id,
    project_percent,
    project_staff,
  } = addProject;

  const handleChange = (e) => {
    e.preventDefault();
    // console.log(e.target.value)
    // console.log("handle changed called");
    setaddProject({ ...addProject, [e.target.name]: e.target.value });
    // console.log(addProject)
  };

  const handleSubmit = async () => {
    setIsSubmit(true)
    const formattedData = {
      ...addProject,
      user_id: user,
    };
    await AddnewProject(formattedData).then((result) => {
      setApiMsg(result?.message);
      if (result?.message) {
        setOpenSnackbar(true)
      }
      setIsSubmit(false)
    });
    setTimeout(() => { setOpenSnackbar(false) }, [1500])
    setTimeout(() => { handleClose() }, [1800])
  };

  const getOrg = async () => {
    const urlOrg = await fetchApi("/api/orgs");
    const orglist = await urlOrg.json();
    // console.log(orglist);
    setOrgs(orglist.palyload);
  };

  useEffect(() => {
    getOrg();
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  let menuList = [
    {
      id: Math.floor(Math.random() * 10000),
      value: "4000",
      title: "4000 - Franchise Sales",
    },
    {
      id: Math.floor(Math.random() * 10000),
      value: "4100",
      title: "4100 - Royaltie",
    },
    {
      id: Math.floor(Math.random() * 10000),
      value: "4105",
      title: "4105 - Master Franchise Royalties",
    },
    {
      id: Math.floor(Math.random() * 10000),
      value: "4110",
      title: "4110 - National Marketing",
    },
    {
      id: Math.floor(Math.random() * 10000),
      value: "4300",
      title: "4300 - Franchisee IT - Control Collected",
    },
    {
      id: Math.floor(Math.random() * 10000),
      value: "4700",
      title: "4700 - Supplier Contributions",
    },
    {
      id: Math.floor(Math.random() * 10000),
      value: "4800",
      title: "4800 - Franchisee sales and branding material",
    },
    {
      id: Math.floor(Math.random() * 10000),
      value: "4850",
      title: "4850 - Franchisee IT - Collected",
    },
    {
      id: Math.floor(Math.random() * 10000),
      value: "4900",
      title: "4900 - Othere Revenue",
    },
  ];

  return (
    <><div className="addProjectform-container">
      <div>
        <div className="flex">
          <input
            onChange={handleChange}
            type="text"
            name="project_title"
            value={project_title}
            placeholder="Project Title" />
          <input
            onChange={handleChange}
            type="number"
            name="cost"
            value={cost}
            placeholder="$ Cost" />
        </div>
        <div className="flex">
          <input
            onChange={handleChange}
            type="text"
            name="description"
            value={description}
            placeholder="Description" />
          <select name="gl_code" label="GL-Code" onChange={(e) => handleChange(e)} MenuProps={MenuProps}>
            <option>GL Code</option>
            {glCodeList?.map((item) => {
              return <option key={item?.id} value={item?.value}>{item?.title}</option>
            })}
          </select>

          {/* <FormControl size="small" sx={{ width: "50%" }}>
      <InputLabel>GL-Code</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label="GL-Code"
        name="gl_code"
        MenuProps={MenuProps}
        onChange={handleChange}
      >
        {menuList.map((elem) => {
          return (
            <MenuItem key={elem.id} value={elem.value}>
              {elem.title}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl> */}
        </div>
        <div className="box-1">
          <select
            onChange={(e) => handleChange(e)}
            type="text"
            name="category"
            value={category}
          >
            <option>Category</option>
            <option value="Cost Recovery">Cost Recovery</option>
          </select>
          <input
            placeholder="Start Date"
            required
            type="text"
            onFocus={(e) => {
              e.target.type = "date";
            }}
            onBlur={(e) => {
              e.target.type = "text";
            }}
            onChange={handleChange}
            name="start_date"
            value={start_date} />
          <input
            placeholder="End Date"
            required
            type="text"
            onFocus={(e) => {
              e.target.type = "date";
            }}
            onBlur={(e) => {
              e.target.type = "text";
            }}
            onChange={handleChange}
            name="end_date"
            value={end_date} />
          <select
            onChange={(e) => handleChange(e)}
            type="text"
            name="color"
            value={color}
          >
            <option>Color</option>
            <option value="Red">Red</option>
            <option value="Blue">Blue</option>
            <option color="Green">Green</option>
          </select>
        </div>

        <div className="addPro-plus-section">
          <div id="split-add">
            Assigned Staff:
            <ProjectStaff
              addProject={addProject}
              setaddProject={setaddProject} />
          </div>

          <div id="split-add">
            Split by Company %:
            <ProjectPercent
              addProject={addProject}
              setaddProject={setaddProject} />
          </div>
        </div>

        <div className="outflowform-button-submit">
          <Button
            onClick={handleClose}
            size="small"
            variant="outlined"
            sx={{ fontSize: "0.6rem" }}
          >
            Cancel
          </Button>

          <LoadingButton
            size="small"
            variant="contained"
            sx={{ fontSize: "0.6rem" }}
            onClick={handleSubmit}
            loading={isSubmit}
            loadingPosition="end"
          >
            Confirm
          </LoadingButton>
        </div>
      </div>
    </div>
      {/* <SnackbarMsg apiMsg={apiMsg} open={setOpenSnackbar} color={"green"} /> */}
    </>
  );
};
export default ProjectForm;

{
  /* <select
            onChange={(e) => handleChange(e)}
            type="text"
            name="gl_code"
            value={gl_code}  
           
          >
            <option>GL-Code</option>
            <option value="4000">4000-Franchise Sales</option>
            <option value="4100">4100-Royalties</option>
            <option value="4105">4105-Master Franchise Royalties</option>
            <option value="4110">4110-National Marketing</option>
            <option value="4300">4300-Franchisee IT - Control Collected</option>
            <option value="4700">4700-Supplier Contributions</option>
            <option value="4800">4800-Franchisee sales and branding material</option>
            <option value="4850-">4850-Franchisee IT - Collected</option>
            <option value="4900">4600-Othere Revenue</option>
           
          </select> */
}

/*   <option>GL-Code</option>
   <option value="4000">4000-Franchise Sales</option>
   <option value="4100">4100-Royalties</option>
   <option value="4105">4105-Master Franchise Royalties</option>
   <option value="4110">4110-National Marketing</option>
   <option value="4300">4300-Franchisee IT - Control Collected</option>
   <option value="4700">4700-Supplier Contributions</option>
   <option value="4800">4800-Franchisee sales and branding material</option>
   <option value="4850-">4850-Franchisee IT - Collected</option>
   <option value="4900">4600-Othere Revenue</option> */
