import { getServerHost } from "./url.service";

/**
 * Convert a JSON object into a FormData object.
 * * This is used in order to allow sending files to the server.
 * * Append the file to the FormData object that is returned
 * * Note: That nested arrays and objects are stringified, so they are must be JSON parsed server-side.
 *
 * @param {*} jsonObject
 */
export const convertJSONToFormData = (jsonObject) => {
  const formData = new FormData();

  Object.entries(jsonObject).forEach(([k, v]) => {
    if (v && (Array.isArray(v) || typeof v === "object")) {
      // Append stringified object/array.
      formData.append(k, JSON.stringify(v));
    } else {
      // Else append the value.
      formData.append(k, v);
    }
  });

  return formData;
};

/**
 * Make a fetch request to the API server.
 * * Automatically adds credentials, as cookies.
 * * Automatically prefixes the URL with the API server.
 *
 * @param {string} url Endpoint path; Omit the server URL.
 * @param {*} [options] Normal fetch parameters.
 * @param {boolean} [hasRetriedRefresh] True when this request has already attempt to refreshed the access token; Prevent refreshing any more to avoid infinite loop.
 */
export const fetchApi = async (
  url,
  options = {},
  hasRetriedRefresh = false
) => {
  /**
   * Refresh access token than retry the same request.
   */
  const retryWithRefresh = async () => {
    // Refresh access token.
    const hasRefreshed = await refreshAccessToken();

    // Retry the request.
    if (hasRefreshed) return fetchApi(url, options, true);
  };

  try {
    // Get cookie for access token.
    const accessToken = getAccessTokenFromCookie();
    if (!accessToken && !hasRetriedRefresh) return retryWithRefresh();

    // Attach the authorization bearer token to the Authorization header.
    options.headers = {
      ...options?.headers,
      Authorization: "Bearer " + accessToken, // Append/override the 'Authorization' header.
    };

    // Make request.
    const response = await fetch(getServerHost() + url, options);

    // Unauthorized error.
    if (response?.status === 401 && !hasRetriedRefresh) {
      return retryWithRefresh();
    }

    // Else parse to JSON and return.
    // const data = await response.json()
    // return data

    // Return the response.
    return response;
  } catch (error) {
    console.error(error.message || error);
  }
};

/**
 * Get the access token from the cookie.
 * * It is to be added to the header in subsequent requests as in the 'Authorization' header
 */
const getAccessTokenFromCookie = () => {
  const ACCESS_TOKEN_COOKIE_NAME = "access-token";
  return document.cookie
    .split("; ")
    .find((f) => f.includes(ACCESS_TOKEN_COOKIE_NAME))
    ?.split("=")[1];
};

/**
 * Attempt to get a new access token
 * * Requires refresh token stored in a secure cookie
 * * If the refresh token is invalid, the user must re-authenticate.
 */
const refreshAccessToken = async () => {
  try {
    // Attempt to get new access token with the refresh token.
    const response = await fetch(`${getServerHost()}/api/auth/refresh`, {
      credentials: "include",
    });
    // Unauthorized error.
    if (response?.status === 401) {
      // Unable to validate refresh token.
      await signOut(true);
      return false;
    }
    // Successful.
    return true;
  } catch (error) {
    // Unable to validate refresh token.
    await signOut(true);
    return false;
  }
};

/**
 * Handle sign-out
 *
 * @param {boolean} makeSignOutRequest True to make the sign out request to the server, to delete the JWT cookies.
 */
export const signOut = async (skipSignOutRequest) => {
  // Make request to sign out if requested.
  if (!skipSignOutRequest)
    await fetch(`${getServerHost()}/api/auth/sign-out`, {
      credentials: "include",
    });

  // From sessionsStorage.
  sessionStorage.removeItem("user");

  // Redirect to login page.
  window.location = "/";

  // Return true if it has signed out.
  return true;
};

export const AddUser = async (user) => {
  try {
    console.log("user", user);
    const data = await fetchApi("/api/userorgmaps/user", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    });
    const userData = await data.json();
    console.log(userData);
    return userData;
  } catch (error) {
    console.log(error.message);
  }
};

export const updateUser = async (user) => {
  console.log("my-update-data", user);
  try {
    const data = await fetchApi(`/api/userorgmaps/update-user/${user.userId}`, {
      method: "PATCH",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(user),
    });
    const updatedData = await data.json();
    return updatedData;
  } catch (error) {
    console.log(error.message);
  }
};
// all org
export const GetAllOrg = async () => {
  try {
    const data = await fetchApi("/api/userorgmaps/all-org");
    const response = await data.json();
    console.log(response?.Organisation);
    return response?.Organisation;
  } catch (error) {
    console.log(error.message);
  }
};
export const GetAllUser = async (params) => {
  try {
    const data = await fetchApi(
      `/api/userorgmaps/all-user?page=${params.page}&size=${params.size}`
    );
    const response = await data.json();
    return {
      rows: response?.data?.rows,
      totalPages: response?.totalPages,
    };
  } catch (error) {
    console.log(error.message);
  }
};

// emp data
export const getAllEmp = async (role) => {
  try {
    const all_emp = await fetchApi(`/api/users/${role}`);
    const data = await all_emp.json();
    console.log("MY_DATAS", data);
    return data.emp.rows;
  } catch (error) {
    console.log(error.message);
  }
};

// delete user

export const deleteEmp = async (id) => {
  try {
    const deleteItem = await fetchApi(`/api/userorgmaps/del/${id}`, {
      method: "DELETE",
    });
    const removeData = await deleteItem.json();
    return removeData;
  } catch (error) {
    console.log(error.message);
  }
};
