import React, { useEffect } from "react";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import "./BillProducts.css";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";

const BillProducts = ({ bill, setBill, isTaxExcl }) => {
  console.log("isTaxExcl", isTaxExcl)
  const [products, setProducts] = useState([]);
  const [prod, setProd] = useState({});

  const addProduct = (e) => {
    e.preventDefault();

    const newProduct = {
      description: "",
      quantity: "",
      price: "",
      // gl_code: "",
      tax_rate: "",
    };

    //setProd(newProduct);

    setProducts([...products, newProduct]);
  };

  const handleChange = (idx, event) => {
    const { name, value } = event.target;
    const updatedProducts = products.map((product, index) =>
      idx === index ? { ...product, [name]: value } : product
    );
    const taxFirst = updatedProducts[0]?.tax_rate;
    setProducts(
      updatedProducts.map((e) => ({
        ...e,
        tax_rate: taxFirst,
      }))
    );
  };
  useEffect(() => {
    setBill({ ...bill, bill_products: products });
  }, [products]);

  return (
    <div style={{ margin: "10px 0" }} className="billProduct">
      <Table component={Paper} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell align="">Description</TableCell>
            <TableCell align="">Quantity</TableCell>
            <TableCell align="">Price</TableCell>
            <TableCell align="">Tax Rate</TableCell>
            <TableCell align="">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((element, index) => (
            <TableRow key={element.name}>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="description"
                  InputProps={{
                    style: { fontSize: "12px", height: 30, width: "100%" },
                  }}
                  value={element.description}
                  required
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="number"
                  name="quantity"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  value={element.quantity}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="price"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  value={element.price}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="tax_rate"
                  disabled={index > 0 || isTaxExcl}
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  value={element.tax_rate}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell align="left">
                <TextField
                  id="outlined-basic"
                  size="small"
                  required
                  type="number"
                  name="amount"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  value={element.quantity * element.price}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <div>
        <FontAwesomeIcon
          icon={faPlusCircle}
          onClick={addProduct}
          style={{
            color: "#1976d2",
            height: "30px",
            margin: "15px 0",
            display: "flex",
            justifyContent: "flex-start",
          }}
        />
      </div>
    </div>
  );
};

export default BillProducts;

{
  /* <table>
        <thead>
          <tr>
            <th>Description</th>
            <th>Quantity</th>
            <th>Price</th>
            <th>Account</th>
            <th>Tax Rate</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          {products.map((element, index) => (
            <tr key={index}>
              <td>
                <input
                  type="text"
                  name="description"
                  value={element.description}
                  required
                  onChange={(event) => handleChange(index, event)}
                />
              </td>
              <td>
                <input
                  type="number"
                  name="quantity"
                  value={element.quantity}
                  onChange={(event) => handleChange(index, event)}
                />
              </td>
              <td>
                <input
                  required
                  type="number"
                  name="price"
                  value={element.price}
                  onChange={(event) => handleChange(index, event)}
                />
              </td>
              <td>
                <input
                  type="text"
                  name="gl_code"
                  value={element.gl_code}
                  onChange={(event) => handleChange(index, event)}
                />
                <select
                  required
                  type="text"
                  name="gl_code"
                  value={element.gl_code}
                  onChange={(event) => handleChange(index, event)}
                >
                  <option>GL Code</option>
                  <option value="4000">4000-Franchise Sales</option>
                  <option value="4100">4100-Royalties</option>
                  <option value="4105">4105-Master Franchise Royalties </option>
                  <option value="4110">4110-National Marketing</option>
                  <option value="4300">4300-Franchisee IT</option>
                  <option value="4600">4600-Meeting Fees</option>
                  <option value="4700">4700-Supplier Contributions</option>
                  <option value="4800">4800-Sundry Income</option>
                  <option value="4850">4850-Franchisee IT - Collected</option>
                  <option value="4950">
                    4950-Monthly Traffic Loss Recovery
                  </option>
                  <option value="4955">4955-Franchisee Marketing Other</option>
                  <option value="4960">4960-Broker Fees, Commissions</option>
                  <option value="4300">4300</option>
                  <option value="4000">4000</option>
                  <option value="4900">4900</option>
                  <option value="5095">5095</option>
                  <option value="5102">5102</option>
                </select>
              </td>
              <td>
                <input
                  required
                  type="number"
                  name="tax_rate"
                  disabled={index > 0}
                  value={element.tax_rate}
                  onChange={(event) => handleChange(index, event)}
                />
              </td>
              <td>
                <input
                  required
                  type="number"
                  name="amount"
                  value={element.quantity * element.price}
                  onChange={(event) => handleChange(index, event)}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table> */
}
