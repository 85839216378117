import React, { useEffect, useRef } from "react";
import "./OutflowForm.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Addbill, getBill } from "../../services/Currency.service";
import BillProducts from "../BillProducts/BillProducts";
import { convertJSONToFormData, fetchApi } from "../../services/api.service";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box, height, margin } from "@mui/system";
import { Button, MenuItem, Paper, Snackbar } from "@mui/material";
import Spinner from "../../components/Spinner/Spinner";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";
import { glCodeList } from "../../Data/data";

const initvalues = {
  invoice_no: "",
  invoice_url: "",
  vendor: "",
  org: "",
  issue_date: "",
  due_date: "",
  po_no: "",
  po_amount: "",
  reference: "",
  currency: "",
  is_tax_exclusive: "",
  bill_products: [],
};

const OutflowForm = ({ handleClose, setCallBillApi, callBillApi }) => {
  const [bill, setBill] = useState(initvalues);
  const [st, setSt] = useState(0);
  const [tx, setTx] = useState(0);
  const [tot, setTot] = useState(0);
  const [totls, setTotls] = useState({ sub_t: 0, tol_gst: 0, tot_amt: 0 });
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)
  const [isTaxExcl, setIsTaxExcl] = useState(null)

  const {
    invoice_no,
    invoice_url,
    issue_date,
    due_date,
    po_no,
    po_amount,
    reference,
    currency,
    is_tax_exclusive,
    bill_products,
    gl_code,
  } = bill;

  const handleChange = (e) => {
    console.log(e.target.value, "target")
    e.preventDefault();
    // console.log("handle changed called");
    setIsSubmitable(false);
    if (e.target.name === "vendor") {
      setBill({ ...bill, vendor: autocompleteValue });
    }
    setBill({ ...bill, [e.target.name]: e.target.value });
    if (bill?.is_tax_exclusive === false) {
      setIsTaxExcl(true)
    } else {
      setIsTaxExcl(false)
    }
  };

  const calculateTotals = async () => {
    let subT = 0;
    let txr = 0;
    let totamt = 0;
    for (let i = 0; i < bill_products.length; i++) {
      subT +=
        Number(bill_products[i].quantity) * Number(bill_products[i].price);
    }

    if (is_tax_exclusive === "true") {
      txr = (subT * bill_products[0].tax_rate) / 100;
    } else {
      console.log("test");
      txr = 0;
    }

    // console.log("a", subT, txr, totamt);
    totamt = txr + subT;
    const newTotal = { ...totls, sub_t: subT, tol_gst: txr, tot_amt: totamt };
    setTotls(newTotal);
    setIsSubmitable(true);
    return newTotal;
  };

  const calculateGST = () => {
    let txr = 0;
    txr = st * 0.15;
    setTx(txr);

    calculateTotAmount();
  };

  const calculateTotAmount = () => {
    let tls = Number(st) + Number(tx);

    setTot(tls);
  };

  const handleSubmit = async () => {
    setIsSubmit(true)
    const formattedData = {
      ...bill,
    };

    // Convert to submit as a FormData object.
    // Required to send the file to the server.
    const formData = convertJSONToFormData(formattedData);
    if (selectedFile) formData.append("attachment", selectedFile);

    await Addbill(formData).then((result) => {
      // navigate("/home/outflowbill");
      setOpenSnackbar(true)
      setApiMsg(result?.message)
      setIsLoading(true);
      setIsSubmit(true)
      setCallBillApi(!callBillApi)
    });
    setTimeout(() => { setOpenSnackbar(false) }, [1500])
    setTimeout(() => { handleClose() }, 1000);
  };

  // console.log("snackbar vale", openSnackbar)

  const [vendors, setVendors] = useState([]);
  const getVendor = async () => {
    const url = await fetchApi("/api/vendors");
    const vendorlist = await url.json();
    setVendors(vendorlist.palyload);
  };

  const [orgs, setOrgs] = useState([]);
  const [isSumitable, setIsSubmitable] = useState(false);
  const getOrg = async () => {
    const urlOrg = await fetchApi("/api/orgs");
    const orglist = await urlOrg.json();
    setOrgs(orglist.palyload);
  };
  function setBillAndResetIsSubmitable(data) {
    setBill(data);
    setIsSubmitable(false);
  }
  useEffect(() => {
    getVendor();
  }, []);
  useEffect(() => {
    getOrg();
  }, []);

  //Autocomplete
  //......
  //.....
  const [jsonresult, setJsonresult] = useState([]);
  const test = async () => {
    const data = await fetchApi(`/api/vendors/search?name=exter`);
    const response = await data.json();
    setJsonresult(response.payload);
    // console.log(response.payload);
  };

  useEffect(() => {
    // test();

    // Example: Set autocomplete with existing value (e.g. for initial load).
    const exampleInitialAutocompleteValue = "";
    setAutoCompleteValue(exampleInitialAutocompleteValue);
    handleDebounceSearch(exampleInitialAutocompleteValue);
  }, [isSubmit]);

  //.......
  //.......

  const ref = React.useRef();
  const handleCancel = () => {
    handleClose();
    ref.current.reset();
    setBill(initvalues);
  };

  // START Test search autocomplete things.

  const debounceTimeout = useRef();
  const [autocompleteLoading, setAutocompleteLoading] = useState(false);
  const [autocompleteValue, setAutoCompleteValue] = useState(null);

  const requestAutocompleteList = async (search) => {
    // No search - return an empty list.
    if (!search?.trim()) return [];

    setAutocompleteLoading(true);
    try {
      const response = await fetchApi(`/api/vendors/search?name=${search}`);
      const data = await response.json();
      return data.payload;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      setAutocompleteLoading(false);
    }
  };

  const handleDebounceSearch = (search) => {
    const DEBOUNCE_MS = 500;

    // Clear the previous timeout.
    clearTimeout(debounceTimeout.current);

    // Debouncing.
    debounceTimeout.current = setTimeout(async () => {
      // Do request.
      const returned = await requestAutocompleteList(search);
      setJsonresult(returned);
    }, DEBOUNCE_MS);
  };

  const onAutocompleteChange = (_e, v) => {
    // console.log("v :>> ", v);
    // setAutoCompleteValue(v)
    // setAutoCompleteValue(v ? v.id : null)
    const vendor = v ? v.name : null;
    setAutoCompleteValue(vendor);
    // console.log(bill);
    setBill({ ...bill, vendor });

    // Clear search if the input was cleared.
    if (!v) handleDebounceSearch(null);
  };

  // END Test search autocomplete things.

  return (
    <>
      {isLoading ? (
        <div style={{ width: "900px" }}>
          <Spinner />
        </div>
      ) : (
        <div className="outflowform-container">
          <form
            ref={ref}
            id="form-id"
            className="semi-container"
            onSubmit={(e) => {
              e.preventDefault();
              if (isSumitable && bill.bill_products.length) {
                handleSubmit();
              } else if (!isSumitable && bill.bill_products.length) {
                calculateTotals();
              }
            }}
          >
            <div className="form-div">
              <div className="form-div-field">
                <select
                  required
                  name="org"
                  style={{
                    width: "100%",
                    height: 30,
                    border: "1px solid #cccccc",
                  }}
                  className="box1-options"
                  id="outlined-select-currency"
                  onChange={handleChange}
                  value={bill["org"]}
                >
                  <option value={undefined}>To*</option>
                  {orgs.map((elements, index) => {
                    return (
                      <option value={elements.name} type="text" key={index}>
                        {elements.name}
                      </option>
                    );
                  })}
                </select>

                <TextField
                  required
                  type="text"
                  onFocus={(e) => {
                    e.target.type = "date";
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                  }}
                  className="box2-options"
                  name="issue_date"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  id="issue-date outlined-basic"
                  size="small"
                  value={issue_date}
                  sx={{ width: "100%" }}
                  placeholder="Issue date*"
                  onChange={handleChange}
                />
                <TextField
                  id="outlined-basic"
                  type="text" required
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  sx={{
                    ".MuiFormLabel-root[data-shrink=false]": { top: "1rem" },
                  }}
                  className="box3-options"
                  name="reference"
                  size="small"
                  value={reference}
                  placeholder="Reference*"
                  onChange={handleChange}
                />
              </div>
              <div className="form-div-field">
                <Autocomplete
                  options={jsonresult}
                  limitTags={2}
                  name="vendor"
                  onChange={onAutocompleteChange}
                  loading={autocompleteLoading}
                  value={autocompleteValue}
                  size="small"
                  sx={{ width: "100%" }}
                  isOptionEqualToValue={(option, value) =>
                    option?.name === value
                  }
                  getOptionLabel={(option) => option?.name || option}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={(e) => handleDebounceSearch(e.target.value)}
                      label="From*"
                      size="small"
                    />
                  )}
                />
                <TextField
                  required
                  type="text"
                  onFocus={(e) => {
                    e.target.type = "date";
                  }}
                  onBlur={(e) => {
                    e.target.type = "text";
                  }}
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  className="box2-options"
                  name="due_date"
                  id="due_date outlined-basic"
                  size="small"
                  value={due_date}
                  sx={{ width: "100%" }}
                  placeholder="Due date*"
                  onChange={handleChange}
                />
                <select
                  name="currency"
                  select
                  size="small"
                  value={bill.currency}
                  onChange={handleChange}
                  label="Currency"
                  id="outlined-basic"
                  style={{ height: 30, border: "1px solid #cccccc" }}
                  className="box3-options"
                  required
                >
                  <option>Currency*</option>
                  <option value="NZD">NZD</option>
                  <option value="AUD">AUD</option>
                  <option value="GBP">GBP</option>
                  <option value="USD">USD</option>
                </select>
              </div>
              <div className="form-div-field">
                <TextField
                  required
                  type="text"
                  sx={{ width: 200 }}
                  className="box1-options"
                  name="invoice_no"
                  size="small"
                  value={invoice_no}
                  id="outlined-basic"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  placeholder="Invoice Number*"
                  onChange={handleChange}
                  variant="outlined"
                />
                <TextField
                  type="number" required
                  sx={{ width: 200 }}
                  className="box2-options"
                  name="po_no"
                  id="outlined-basic"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  value={po_no}
                  size="small"
                  placeholder="PO Number*"
                  onChange={handleChange}
                />
                <select
                  required
                  select
                  size="small"
                  style={{ height: 30, border: "1px solid #cccccc" }}
                  className="box3-options"
                  name="is_tax_exclusive"
                  value={bill.is_tax_exclusive}
                  onChange={handleChange}
                >
                  <option>Tax type*</option>
                  <option value={false}>Tax-Exclusive</option>
                  <option value={true}>Tax-Inclusive</option>
                </select>
                {/* <TextField
              name="currency"
              select size="small"
              value={bill.currency}
              onChange={handleChange}
              label="Currency" id="outlined-basic"
              className="box3-options" InputProps={{ style: { fontSize: '12px', height: 30 } }}
              sx={{ '.MuiFormLabel-root[data-shrink=false]': { top: "-5px" } }}

              required
            >
              <option value="NZD">NZD</option>
              <option value="AUD">AUD</option>
              <option value="GBP">GBP</option>
              <option value="USD">USD</option>
            </TextField> */}
              </div>
              <div className="form-div-field">
                <TextField
                  required
                  type="file"
                  sx={{ width: 200 }}
                  id="outlined-basic"
                  className="box1-options"
                  size="small"
                  onChange={(e) => setSelectedFile(e.target.files[0])}
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  variant="outlined"
                />
                <TextField
                  type="number" required
                  sx={{ width: 200 }}
                  className="box2-options"
                  name="po_amount"
                  id="outlined-basic"
                  value={po_amount}
                  size="small"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  placeholder="PO Amount*"
                  onChange={handleChange}
                />
                {/* <select
                  required
                  type="text"
                  name="gl_code"
                  style={{
                    height: 30,
                    width: "100%",
                    border: "1px solid #cccccc",
                    borderRadius: "3px",
                  }}
                  value={gl_code}
                  onChange={handleChange}
                >
                  <option>GL Code*</option>
                  <option value="4300">4300 - Franchisee IT - Control Collected</option>
                  <option value="4000">4000 - Franchise Sales</option>
                  <option value="4001">4001 - Royaltie</option>
                  <option value="4005">4005 - Master Franchise Royalties</option>
                  <option value="4110">4110 - National Marketing</option>
                  <option value="4700">4700 - Supplier Contributions</option>
                  <option value="4800">4800 - Franchisee sales and branding material</option>
                  <option value="4850">4850 - Franchisee IT - Collected</option>
                  <option value="4900">4900 - Other Revenue</option>
                  <option value="5095">5095 - Franchise Recruitment</option>
                </select> */}
              </div>
            </div>

            <div style={{ width: "22%", padding: "10px 0px" }}>
              <select
                required
                type="text"
                name="gl_code"
                style={{
                  height: 30,
                  width: "100%",
                  border: "1px solid #cccccc",
                  borderRadius: "3px",
                }}
                value={gl_code}
                onChange={handleChange}
              >
                <option>GL Code*</option>
                {glCodeList?.map((item) => {
                  return <option key={item?.id} value={item?.value}>{item?.title}</option>
                })}
                {/* <option value="4300">4300 - Franchisee IT - Control Collected</option>
                <option value="4000">4000 - Franchise Sales</option>
                <option value="4001">4001 - Royaltie</option>
                <option value="4005">4005 - Master Franchise Royalties</option>
                <option value="4110">4110 - National Marketing</option>
                <option value="4700">4700 - Supplier Contributions</option>
                <option value="4800">4800 - Franchisee sales and branding material</option>
                <option value="4850">4850 - Franchisee IT - Collected</option>
                <option value="4900">4900 - Other Revenue</option>
                <option value="5095">5095 - Franchise Recruitment</option> */}
              </select>
            </div>

            <BillProducts bill={bill} setBill={setBillAndResetIsSubmitable} isTaxExcl={isTaxExcl} setIsTaxExcl={setIsTaxExcl} />

            <div className="outflowbill-totals">
              <div className="outflow-totals-box">
                <label> Sub-Total : </label>
                <input
                  disabled
                  type="text"
                  name="sub_total"
                  value={totls.sub_t}
                />
              </div>
              <br />
              <div className="outflow-totals-box">
                <label> Total GST : </label>
                <input
                  disabled
                  type="text"
                  name="total_gst"
                  value={totls.tol_gst}
                />
              </div>
              <br />
              <div className="outflow-totals-box">
                <label>Total Amt :</label>
                <input
                  disabled
                  type="text"
                  name="total_amt"
                  value={totls.tot_amt}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="outflowform-button-submit">
              <Button
                sx={{ fontSize: "0.6rem" }}
                size="small"
                variant="outlined"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              {!isSumitable && (
                <Button
                  size="small"
                  sx={{ fontSize: "0.6rem" }}
                  type="submit"
                  variant="contained"
                >
                  Save
                </Button>
              )}
              {isSumitable && (
                <LoadingButton
                  size="small"
                  sx={{ fontSize: "0.6rem" }}
                  variant="contained"
                  type="submit"
                  loading={isSubmit}
                  loadingPosition="end"
                >
                  Submit
                </LoadingButton>
              )}
            </div>
          </form>
        </div>
      )}
      {/* <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={"green"} /> */}
    </>
  );
};

export default OutflowForm;

{
  /* <div className="">
<div className="box-1">
  <select
        required
        name="vendor"
        className="box1-options"
        onChange={handleChange}
        value={bill["vendor"]}
      >
        <option value={undefined}>From</option>
        {vendors.map((elements, index) => {
          return (
            <option value={elements.name} type="text" key={index}>
              {elements.name}
            </option>
          );
        })}
      </select>
  <Autocomplete
    options={jsonresult}
    sx={{ width: 200 }}
    limitTags={10}
    name="vendor"
    onChange={onAutocompleteChange}
    loading={autocompleteLoading}
    value={autocompleteValue}
    size="small"
    isOptionEqualToValue={(option, value) =>
      option?.name === value
    }
    getOptionLabel={(option) => option?.name || option}
    renderInput={(params) => (
      <TextField
        {...params}
        onChange={(e) => handleDebounceSearch(e.target.value)}
        label="From"
      />
    )}
  />

  <select
    required
    name="org"
    style={{ width: 200 }}
    className="box1-options"
    id="outlined-select-currency"
    onChange={handleChange}
    value={bill["org"]}
  >
    <option value={undefined}>To</option>
    {orgs.map((elements, index) => {
      return (
        <option value={elements.name} type="text" key={index}>
          {elements.name}
        </option>
      );
    })}
  </select>
  <input
    required
    type="text"
    className="box1-options"
    name="invoice_no"
    value={invoice_no}
    placeholder="# Invoice Number"
    onChange={handleChange}
  />
  <TextField
    required
    type="text"
    sx={{ width: 200 }}
    className="box1-options"
    name="invoice_no" size="small"
    value={invoice_no}
    placeholder="# Invoice Number"
    onChange={handleChange} variant="outlined" />
  <TextField
    required
    type="file"
    sx={{ width: 200 }}
    className="box1-options" size="small"
    onChange={(e) => setSelectedFile(e.target.files[0])}
    variant="outlined" />
  <input
    type="file"
    className="box1-options"
    onChange={(e) => setSelectedFile(e.target.files[0])}
    placeholder="Upload"
  />
</div>

<div className="box-2">
  <span htmlFor="issueDate">Issue Date</span>
  <input
    required
    type="text"
    onFocus={(e) => {
      e.target.type = "date";
    }}
    onBlur={(e) => {
      e.target.type = "text";
    }}
    className="box2-options"
    name="issue_date"
    id="issue-date"
    value={issue_date}
    placeholder="Issue date"
    onChange={handleChange}
  />
  <TextField
    required
    sx={{ width: 200 }}
    type="text"
    onFocus={(e) => {
      e.target.type = "date";
    }}
    onBlur={(e) => {
      e.target.type = "text";
    }}
    className="box2-options"
    name="issue_date"
    id="issue-date" size="small"
    value={issue_date}
    placeholder="Issue date"
    onChange={handleChange}
  />

  <div className="due_date">
    <span htmlFor="issueDate">Due Date</span>
    <input
      required
      type="text"
      onFocus={(e) => {
        e.target.type = "date";
      }}
      onBlur={(e) => {
        e.target.type = "text";
      }}
      name="due_date"
      className="box2-options"
      value={due_date}
      placeholder="Due Date"
      onChange={handleChange}
    />
    <TextField
      required
      type="text"
      onFocus={(e) => {
        e.target.type = "date";
      }}
      onBlur={(e) => {
        e.target.type = "text";
      }}
      className="box2-options"
      name="due_date"
      id="due_date" size="small"
      value={due_date}
      placeholder="Due date"
      onChange={handleChange}
    />
  </div>

  <TextField
    required
    type="number"
    sx={{ width: 200 }}
    className="box2-options"
    name="po_no"
    value={po_no} size="small"
    placeholder="PO Number"
    onChange={handleChange}
  />

  <TextField
    required
    type="number"
    sx={{ width: 200 }}
    className="box2-options"
    name="po_amount"
    value={po_amount} size="small"
    placeholder="PO Amount"
    onChange={handleChange}
  />
</div>

<div className="box-3">
  <TextField
    required
    type="text"
    sx={{ width: 200 }}
    className="box3-options"
    name="reference" size="small"
    value={reference}
    placeholder="Reference"
    onChange={handleChange}
  />

  <input
    name="currency"
    select size="small"
    sx={{ width: 200 }}
    onChange={handleChange}
    label="Currency"
    className="box3-options"
    required
  >
    <option value="">Currency</option>
    <option value="NZD">NZD</option>
    <option value="AUD">AUD</option>
    <option value="GBP">GBP</option>
    <option value="USD">USD</option>
  </TextField>

  <TextField
    required select size="small" label="Select Tax" sx={{ width: 200 }}
    className="box3-options"
    name="is_tax_exclusive"
    onChange={handleChange}
  >
    <option>Select Tax</option>
    <option value={false}>Tax-Exclusive</option>
    <option value={true}>Tax-Inclusive</option>
  </TextField>
</div>
</div> */
}
