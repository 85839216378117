import React, { useEffect } from "react";
import "./OutflowSplitbillShare.css";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { fetchApi } from "../../services/api.service";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const OutflowSplitbillShare = ({ splitbill, setSplitbill, amt, currency }) => {
  const [splitbillshare, setSplitbillshare] = useState([]);

  useEffect(() => {
    setSplitbill({ ...splitbill, org_split: splitbillshare });
  }, [splitbillshare]);

  const addSplitbillShare = (event) => {
    event.preventDefault();

    const newSplitbill = {
      id: Date.now(),
      company_abbr: "",
      description: "",
      split_per: "",
      gst: false,
      gst_per: 0,
      gst_amount: 0,
    };

    setSplitbillshare([...splitbillshare, newSplitbill]);
  };

  const handleChange = (idx, event) => {
    const { name, value } = event.target;
    let updatedBill;
    if (name === "gst") {
      let t = 0.0;
      if (currency === "NZD") {
        console.log(splitbillshare[idx].company_abbr);
        if (
          ["TNZ", "RNZ", "ONZ", "ZNZ", "RI", "OI", "ZI"].includes(
            splitbillshare[idx].company_abbr
          )
        ) {
          t = 0.15;
        } else {
          t = 0;
        }
      } else if (currency === "AUD") {
        if (["RAU", "ZAU"].includes(splitbillshare[idx].company_abbr)) {
          t = 0.1;
        } else {
          t = 0;
        }
      } else {
        t = 0;
      }

      updatedBill = splitbillshare.map((newbill, index) =>
        idx === index
          ? {
            ...newbill,
            [name]: event.target.checked,
            gst_per: t,
            gst_amount: (t * Number(newbill.split_per) * amt) / 100,
          }
          : newbill
      );
    } else {
      updatedBill = splitbillshare.map((newbill, index) =>
        idx === index ? { ...newbill, [name]: value } : newbill
      );
    }

    setSplitbillshare(updatedBill);
    setSplitbill({ ...splitbill, org_split: updatedBill });
  };

  const [orgs, setOrgs] = useState([]);
  const getOrg = async () => {
    // console.log(orgs);
    const urlOrg = await fetchApi("/api/orgs");
    const orglist = await urlOrg.json();
    console.log(orglist);
    setOrgs(orglist.palyload);
  };

  const removeItem = (id) => {
    console.log("MYINDEX", id);
    // setSplitbillshare(splitbillshare.filter((elem, i) => i !== ind));
    setSplitbillshare(
      splitbillshare.filter((ele) => {
        return ele.id !== id;
      })
    );
  };

  useEffect(() => {
    getOrg();
  }, []);

  console.log("splitShare", splitbillshare);

  return (
    <div style={{ padding: "10px" }}>
      <Table
        style={{ width: "100%" }}
        component={Paper}
        size="small"
        aria-label="a dense table"
        sx={{ "& .MuiTableCell-root": { fontSize: "12px", fontWeight: "600" } }}
      >
        <TableHead>
          <TableRow>
            <TableCell
              align="left"
              style={{ width: "100px", borderRight: "1px solid #cccccc" }}
            >
              Company
            </TableCell>
            <TableCell
              align="left"
              style={{ width: "200px", borderRight: "1px solid #cccccc" }}
            >
              Description
            </TableCell>
            <TableCell
              align="left"
              style={{ width: "80px", borderRight: "1px solid #cccccc" }}
            >
              Split (%)
            </TableCell>
            <TableCell
              align="left"
              style={{ width: "80px", borderRight: "1px solid #cccccc" }}
            >
              GST
            </TableCell>
            <TableCell
              align="left"
              style={{ width: "120px", borderRight: "1px solid #cccccc" }}
            >
              Total Rate
            </TableCell>
            <TableCell align="left" style={{ width: "120px" }}>
              Local
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {splitbillshare.map((element, index) => (
            <TableRow key={element.id}>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <select
                  type="text"
                  name="company_abbr"
                  className="outflowsplitbillshare"
                  onChange={(event) => handleChange(index, event)}
                  style={{ width: "100%" }}
                >
                  <option>Company</option>
                  {orgs.map((org, idx) => (
                    <option value={org.abbr} key={idx}>
                      {org.name}
                    </option>
                  ))}
                </select>
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="text"
                  name="description"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  value={element.quantity}
                  onChange={(event) => handleChange(index, event)}
                  style={{ width: "100%" }}
                />
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="number"
                  name="split_per"
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                  value={element.price}
                  onChange={(event) => handleChange(index, event)}
                />
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <input
                  type="checkbox"
                  name="gst"
                  className="special-input"
                  checked={element.gst}
                  onChange={(event) => handleChange(index, event)}
                />
                <span>
                  -&nbsp;&nbsp;&nbsp;&nbsp;
                  {(element.gst_per * (Number(element.split_per) * amt)) / 100}
                </span>
              </TableCell>
              <TableCell
                align="left"
                style={{ borderRight: "1px solid #cccccc" }}
              >
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="number"
                  name="total"
                  value={(Number(element.split_per) * amt) / 100}
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                />
              </TableCell>
              <TableCell
                align="left"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <TextField
                  id="outlined-basic"
                  size="small"
                  type="number"
                  name="local-nzd"
                  value={(Number(element.split_per) * amt) / 100}
                  InputProps={{ style: { fontSize: "12px", height: 30 } }}
                />
                {/* {index} */}
                <IconButton
                  onClick={() => removeItem(element.id)}
                  sx={{ width: 0 }}
                >
                  <RemoveCircleOutlineIcon fontSize="small" />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <div>
        <FontAwesomeIcon
          icon={faPlusCircle}
          onClick={addSplitbillShare}
          style={{
            color: "#0056b3",
            height: "30px",
            margin: "10px 0",
            display: "flex",
            justifyContent: "flex-start",
          }}
        />
      </div>
    </div>
  );
};

export default OutflowSplitbillShare;
