import React from "react";
import "./Sidebar.css";
import { NavLink, Outlet } from "react-router-dom";
import { Paper } from "@mui/material";
import { Box } from "@mui/system";

const Sidebar = ({ user }) => {
  return (
    <>
      <Box className="sidebar-container" /* component={Paper} */>
        {/* <div>{user.role === 1 || user.role === 2 ? <h3>INFLOW</h3> : null}</div> */}
        <div className="sidebar-item">
          {user.role === 2 &&
            <NavLink
              to="/home/content"
              style={{ textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              <h3 className="sidebar-link">OUTFLOW</h3>
            </NavLink>}
        </div>
        {/* <div> {user.role === 2 && <h3>PO'S</h3>}</div> */}
        <div>
          <NavLink
            to="/home/timesheet"
            style={{ textDecoration: "none" }}
            className={({ isActive }) => (isActive ? "link-active" : "link")}
          >
            <h3 className="sidebar-link" style={{ textDecoration: "none" }}>
              TIMESHEET
            </h3>
          </NavLink>
        </div>
        <div>
          {user.role === 4 || user.role === 2 ? (
            <NavLink
              to="/home/activeprojects"
              style={{ textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              <h3 className="sidebar-link" style={{ textDecoration: "none" }}>
                PROJECTS
              </h3>
            </NavLink>
          ) : null}
        </div>
        {/* <div>{user.role === 2 && <h3>EXPENSES</h3>}</div> */}
        <div>
          {user.role === 2 &&
            <NavLink
              to="/home/currency"
              style={{ textDecoration: "none" }}
              className={({ isActive }) => (isActive ? "link-active" : "link")}
            >
              <h3 className="sidebar-link" style={{ textDecoration: "none" }}>
                CURRENCY
              </h3>
            </NavLink>}
        </div>
      </Box>
      <Outlet></Outlet>
    </>
  );
};

export default Sidebar;
