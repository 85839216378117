import React, { useContext, useEffect, useState } from "react";
import "./OutflowSplitbill.css";
import OutflowSplitbillShare from "../OutflowSplitbillShare/OutflowSplitbillShare";
import { AddSplitbill } from "../../services/Currency.service";
import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import GlobalContext from "../TimesheetContext/GlobalContext";
import LoadingButton from "@mui/lab/LoadingButton";
import SnackbarMsg from "../../components/superadmin-home/components/Common/Snackbar";
import DirectorbillApproved from "../DirectorbillApproved/DirectorbillApproved";
import { glCodeList } from "../../Data/data";

const OutflowSplitbill = ({
  invoice_id,
  amount,
  GL_code,
  currency,
  handleSplitFlag,
}) => {
  const initialvalues = {
    rule: "",
    gl_code: "",
    org_split: [],
  };

  const { setValue } = useContext(GlobalContext);

  const [splitbill, setSplitbill] = useState(initialvalues);
  const [isSubmit, setIsSubmit] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false)
  const [apiMsg, setApiMsg] = useState(null)


  const { gl_code_rec } = splitbill;
  const { gl_code_pay } = splitbill;
  const { rule } = splitbill;
  const amt = amount;

  const handleChange = (e) => {
    e.preventDefault();
    setSplitbill({ ...splitbill, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsSubmit(true)
    const formattedData = {
      ...splitbill,
    };
    // console.log(formattedData);
    await AddSplitbill(formattedData, invoice_id).then((result) => {
      console.log("split bill 1")
      setOpenSnackbar(true);
      setIsSubmit(false)
      setApiMsg(result?.message)
      setTimeout(() => { setValue(1) }, [1800])
    });
    setTimeout(() => { setOpenSnackbar(false) }, [1500])
  };

  console.log("snackbar val", openSnackbar)

  return (
    <>
      <div>
        <div className="outflowsplitbill-semi-container">
          <div className="outflowsplitbill-rule-glcode">
            <select
              onChange={handleChange}
              name="rule"
              className="outflowsplitbill-select-option"
            >
              <option value="Rule" className="outflowsplitbill-select-option">
                Rule
              </option>
              <option
                value="Royalties"
                className="outflowsplitbill-select-option"
              >
                Royalties
              </option>
              <option
                value="Cost Recovery"
                className="outflowsplitbill-select-option"
              >
                Cost Recovery
              </option>
            </select>
          </div>
          <div>
            <select
              required
              type="text"
              name="gl_code"
              style={{
                height: 30,
                width: "100%",
                border: "1px solid #cccccc",
                borderRadius: "3px",
              }}
              onChange={(e) => handleChange(e)}
            >
              <option>GL Code</option>
              {glCodeList?.map((item) => {
                return <option key={item?.id} value={item?.value}>{item?.title}</option>
              })}
              {/* <option value="null">GL Code</option>
              <option value="4300">4300 - Franchisee IT - Control Collected</option>
              <option value="4000">4000 - Franchise Sales</option>
              <option value="4001">4001 - Royaltie</option>
              <option value="4005">4005 - Master Franchise Royalties</option>
              <option value="4110">4110 - National Marketing</option>
              <option value="4700">4700 - Supplier Contributions</option>
              <option value="4800">4800 - Franchisee sales and branding material</option>
              <option value="4850">4850 - Franchisee IT - Collected</option>
              <option value="4900">4900 - Other Revenue</option>
              <option value="5095">5095 - Franchise Recruitment</option> */}
            </select>
          </div>
          {/* <select
            required
            type="text"
            name="gl_code_rec"
            value={gl_code_rec}
            className="outflowsplitbill-rule-glcode"
            onChange={(e) => handleChange(e)}
          >
            <option>Gl-Code(Rec)</option>
            <option value="4300">4300</option>
            <option value="4000">4000</option>
            <option value="4900">4900</option>
            <option value="5095">5095</option>
            <option value="5102">5102</option>
          </select> */}
          {/* <select
            required
            type="text"
            name="gl_code_pay"
            value={gl_code_pay}
            className="outflowsplitbill-rule-glcode"
            onChange={(e) => handleChange(e)}
          >
            <option>Gl-Code(Pay)</option>
            <option value="4300">4300</option>
            <option value="4000">4000</option>
            <option value="4900">4900</option>
            <option value="5095">5095</option>
            <option value="5102">5102</option>
          </select> */}
        </div>

        <OutflowSplitbillShare
          splitbill={splitbill}
          setSplitbill={setSplitbill}
          amt={amt}
          currency={currency} />

        <div className="outflowsplitbill-cancel-confirm-button">
          <Button
            sx={{ fontSize: "0.6rem" }}
            size="small"
            variant="outlined"
            onClick={() => handleSplitFlag()}
          >
            Cancel
          </Button>
          <LoadingButton
            sx={{ fontSize: "0.6rem" }}
            size="small"
            variant="contained" loading={isSubmit}
            loadingPosition="end"
            onClick={handleSubmit}
          >
            Confirm Split
          </LoadingButton>
        </div>
      </div>
      {/* <SnackbarMsg apiMsg={apiMsg} open={openSnackbar} color={"green"} /> */}
    </>

  );
};

export default OutflowSplitbill;
