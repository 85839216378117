import { fetchApi } from "./api.service";

export const AddCurrency = async (convrates, curr) => {
  try {
    //console.log(convrates);
    const data = await fetchApi(`/api/convrates?currency=${curr}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(convrates),
    });
    const currRate = await data.json();
    console.log(currRate);
    return currRate;
  } catch (error) {
    console.log(error.message);
  }
};

//.............
export const Addbill = async (user) => {
  try {
    console.log("cat", user);
    const data = await fetchApi("/api/bills", {
      method: "POST",
      body: user,
    });
    const userData = await data.json();
    console.log(userData);
    return userData;
  } catch (error) {
    console.log(error.message);
  }
};

//..............
export const getBill = async () => {
  try {
    const data = await fetchApi("/api/bills");
    const bill = await data.json();
    console.log(bill.palyload);
    return bill;
  } catch (error) {
    console.log(error);
  }
};

//.............
export const getBillFileUrl = async (billId) => {
  try {
    const data = await fetchApi(`/api/bills/file/${billId}`);
    const bill = await data.json();
    return bill.url;
  } catch (error) {
    console.log(error);
  }
};

//........
export const getSplitbillData = async () => {
  try {
    const data = await fetchApi("/api/splitbills");
    const bill = await data.json();
    console.log(bill.palyload, "service");
    return bill.palyload;
  } catch (error) {
    console.log(error);
  }
};

//............
export const AddSplitbill = async (splitbill, invoice_id) => {
  try {
    console.log("splibill array in api service", splitbill);
    const data = await fetchApi(`/api/splitbills/${invoice_id}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(splitbill),
    });
    const splitData = await data.json();
    return splitData;
  } catch (error) {
    console.log(error.message);
  }
};

//.............
export const DirectorApproves = async (splitbill_id, splitbillorg_id) => {
  try {
    const data = await fetchApi(
      `/api/splitbills/dirstatus?splitbillorg_id=${splitbillorg_id}&splitbill_id=${splitbill_id}`
    );
    const response = await data.json();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

//............
export const GetUsers = async () => {
  try {
    const data = await fetchApi("/api/users");
    const response = await data.json();
    console.log(response.palyload);
    return response.palyload;
  } catch (error) {
    console.log(error.message);
  }
};

//........
export const RefusesplitBill = async (splitbill_id, dirid, note) => {
  try {
    const data = await fetchApi(
      `/api/splitbills/${splitbill_id}?dirid=${dirid}&note=${note}`,
      {
        method: "DELETE",
      }
    );
    const response = await data.json();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

//..........
export const DirectorRefusesplitBill = async (
  splitbill_id,
  dirid,
  note,
  isRejected
) => {
  try {
    const data = await fetchApi(
      `/api/splitbills/refuse/${splitbill_id}?dirid=${dirid}&note=${note}&isRejected=${isRejected}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(),
      }
    );
    const response = await data.json();
    console.log(splitbill_id);
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

//...........
export const RefusesNotes = async () => {
  try {
    const data = await fetchApi("/api/refusals");
    const response = await data.json();
    // console.log("refusesal note in sevice", response.payload);
    return response.payload;
  } catch (error) {
    console.log(error.message);
  }
};

//............
// export const RedirectBill = async (billinvoice_id, refusal_note_id) => {
//   try {
//     const data = await fetchApi(
//       `/api/splitbills/redirect?billinvoice_id=${billinvoice_id}&refusal_note_id=${refusal_note_id}`,
//       {
//         method: "DELETE",
//       }
//     );
//     const response = await data.json();
//     return response;
//   } catch (error) {
//     console.log(error.message);
//   }
// };

///........
export const RedirectBill = async (splitbill_id) => {
  try {
    const data = await fetchApi(`/api/splitbills/redirect/${splitbill_id}`, {
      method: "DELETE",
    });
    const response = await data.json();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

export const DeleteBill = async (bill_id) => {
  try {
    const data = await fetchApi(`/api/bills/${bill_id}`, {
      method: "DELETE",
    });
    const response = await data.json();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

//...........
export const GetInvoicefromXero = async (xtid, type) => {
  try {
    const data = await fetchApi(
      `/api/xero/ginvoices?xtid=${xtid}&type=${type}`
    );
    const response = await data.json();
    console.log(response);
    return response.payload;
  } catch (error) {
    console.log(error.message);
  }
};

export const GetInvoiceOfXero = async (org_from, type) => {
  try {
    const data = await fetchApi(
      `/api/xero/ginvoices?org_from=${org_from}&type=${type}`
    );
    const response = await data.json();
    console.log(response);
    return response.payload;
  } catch (error) {
    console.log(error.message);
  }
};

export const ResolvesplitBill = async (splitbill_id, splitbillorg_id) => {
  try {
    const data = await fetchApi(
      `/api/splitbills/resolve/${splitbill_id}?splitbillorg_id=${splitbillorg_id}`,
      {
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(),
      }
    );
    const response = await data.json();
    console.log(splitbill_id);
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

export const getOrgInvoiceUrl = async (invno) => {
  try {
    const data = await fetchApi(`/api/orginvoices/getinv/${invno}`);
    const dataResponse = await data.json();
    console.log(dataResponse.url);
    return dataResponse.url;
  } catch (error) {
    console.log(error);
  }
};

//ADD PROJECT
//.....
//...
//...
// Add new Project
export const AddnewProject = async (user) => {
  try {
    console.log(user);
    const data = await fetchApi("/api/activeProject", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(user),
    });
    const userData = await data.json();
    console.log(userData);
    return userData;
  } catch (error) {
    console.log(error.message);
  }
};

//.
//....
///....
//Getting new Project
export const getProject = async (user_id,role) => {
  try {
    const data = await fetchApi(`/api/activeProject?user_id=${user_id}&role=${role}`);
    const Project = await data.json();
    return Project.payload;
  } catch (error) {
    console.log(error);
  }
};

//TIMESHEET API
//
//..
//...
export const getTask = async (userID) => {
  try {
    const data = await fetchApi(`/api/task/reportTask?user_id=${userID}`);
    const task = await data.json();
    // console.log("TASK  IN SERVICES", task.payload);
    return task.payload;
  } catch (error) {
    console.log(error);
  }
};

export const getUserTasks = async (userID) => {
  try {
    const data = await fetchApi(`/api/task/usertask?user_id=${userID}`);
    const task = await data?.json();
    // console.log("TASK  IN SERVICES", task?.payload);
    return task?.payload;
  } catch (error) {
    console.log(error);
  }
};

//
//..
//...
export const addTask = async (data) => {
  try {
    const task = await fetchApi("/api/task", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    const taskData = await task.json();
    // console.log("task DATA", taskData);
    return taskData;
  } catch (error) {
    console.log(error.message);
  }
};

//
//..
//...
export const updateTask = async (data, taskId) => {
  try {
    const task = await fetchApi(`/api/task/${taskId}`, {
      method: "PATCH",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    });
    const taskData = await task.json();
    // console.log("task DATA", taskData);
    return taskData;
  } catch (error) {
    console.log(error.message);
  }
};

export const DeleteTask = async (taskId) => {
  try {
    const data = await fetchApi(`/api/task/${taskId}`, {
      method: "DELETE",
    });
    const response = await data.json();
    return response;
  } catch (error) {
    console.log(error.message);
  }
};

///
//
//
export const AddCurrentTimeStamp = async (time) => {
  try {
    const data = await fetchApi("/api/task/id", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(time),
    });
    const taskTimeStamp = await data.json();
    console.log(taskTimeStamp);
    return taskTimeStamp;
  } catch (error) {
    console.log(error.message);
  }
};
