import React, { useContext } from "react";
import "./Content.css";
import OutflowBill from "../../pages/OutflowBill/OutflowBill";
import RefusedInvoices from "../../pages/RefusedInvoices/RefusedInvoices";
import { NewXero } from "../../pages/NewXero/NewXero";
import NewApprovals from "../../pages/NewApprovals/NewApprovals";
import DashboardNew from "../../pages/DashboardNew/DahsboardNew";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UnpublishedIcon from '@mui/icons-material/Unpublished';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { Paper } from "@mui/material";
import { xeroGreyIcon, xeroIcon } from "../../assest";
import GlobalContext from "../../pages/TimesheetContext/GlobalContext";
import DirectorRefusedInvoices from "../../pages/DirectorRefusedInvoices/DirectorRefusedInvoices";



function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ padding: "0 10px", height: "75vh", overflowY: "auto" }}>
          {children}
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function Content({ user }) {

  const { value, setValue } = useContext(GlobalContext);


  const tabData = [
    { label: 'Outflow', icon: <RequestQuoteIcon />, comp: <OutflowBill user={user} />, index: 0 },
    { label: 'Approvals', icon: <CheckCircleIcon />, comp: <NewApprovals user={user} />, index: 1 },
    { label: 'Refused Invoices', icon: <UnpublishedIcon />, comp: <DirectorRefusedInvoices user={user}/>, index: 2 },
    { label: 'Xero', comp: <NewXero />, index: 3, logo: xeroIcon },
    { label: 'Dashboard', icon: <DashboardIcon />, comp: <DashboardNew />, index: 4 }
  ];

  // const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="content-mainDiv">
      <Paper className="content-container">
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              variant="fullWidth"
              sx={{ height: "3rem", display: "flex", justifyContent: "center", alignItems: "center" }}
            >
              {tabData.map((tab, index) => (
                <Tab
                  user={user}
                  key={index}
                  iconPosition="start"
                  icon={tab.icon ? tab.icon : <img src={value === 3 ? xeroIcon : xeroGreyIcon} alt="tab-icon" width={22} height={22} />}
                  label={<h3 style={{ fontSize: "0.7rem", fontWeight: "bold" }}>{tab.label}</h3>}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </Box>
        </Box>
        {tabData.map((tab, index) => (
          <CustomTabPanel key={index} value={value} index={tab.index} user={user}>
            {tab.comp}
          </CustomTabPanel>
        ))}
      </Paper>
    </div>
  );
}

export default Content;


{/* <Routes>
  <Route path="/currency" element={<Currency />} />
  <Route
    path="/outflowbill"
    element={
      user.role === 2 ? (
        <DirectorApprovalBill user={user} />
      ) : user.role === 3 ? (
        <Timesheet user={user} />
      ) : (
        <OutflowBill />
      )
    }
  />
  <Route path="/outflowform" element={<OutflowForm />} />
  <Route path="/outflowapprovalbill" element={<OutflowApprovalbill />} />
  <Route
    path="/directorapprovalpage"
    element={<DirectorApprovalBill />}
  />
  <Route path="/readytosendtoxero" element={<ReadytosendtoXERO />} />
  <Route
    path="/directorbillapproved"
    element={<DirectorbillApproved user={user} />}
  />
  <Route path="/senttoxero" element={<SenttoXero />} />
  <Route path="/RefusalPage" element={<RefusalPage />} />
  <Route path="/refusedinvoices" element={<RefusedInvoices />} />
  <Route path="/new-xero" element={<NewXero />} />
  <Route path="/approval" element={<NewApprovals />} />
  <Route path="/dashboard" element={<DashboardNew />} />
  <Route
    path="/directorrefusedinvoices"
    element={<DirectorRefusedInvoices user={user} />}
  />

  <Route
    path="/directorpayableinvoices"
    element={<DirectorPayableInvoices user={user} />}
  />
  <Route
    path="/directorreceivableinvoices"
    element={<DirectorReceivableInvoices user={user} />}
  />
  <Route
    path="/readytosendpayinvtoxero"
    element={<SendtoxeroPayInv user={user} />}
  />
  <Route path="/activeprojects" element={<Project user={user} />} />
  <Route path="/timesheet" element={<Timesheet user={user} />} />
  <Route
    path="/TimesheetReports"
    element={<TimesheetReports user={user} />}
  />

  <Route path="/projectForm" element={<ProjectForm user={user} />} />
  <Route path="xero" element={<Xero />} />
  <Route path="/DirectorsView" element={<DirectorsView />} />
</Routes>  */}
