import React, { useEffect, useRef, useState } from "react";
import "../Employee/AddEmployee.css";
import Grid from "@mui/material/Grid";
import {
  AddUser,
  GetAllOrg,
  updateUser,
} from "../../../../services/api.service";
import SnackbarMsg from "../Common/Snackbar";
import Spinner from "../../../Spinner/Spinner";
import { Button } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

const AddEmployee = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  // console.log("props data", props);
  const { onClose, isUpdate, updateData, getAllUser } = props;
  // console.log("User all", getAllUser);
  // console.log("UPDATE DATA", updateData);

  const handleClose = () => {
    onClose(); // Call onClose function passed from parent component
  };

  const radioArray = [
    { name: "Director", value: "director", id: 2 },
    { name: "Employee", value: "employee", id: 3 },
    { name: "Manager", value: "manager", id: 4 },
  ];

  const [OrgName, setOrgName] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const formRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [apiMsg, setAPIMsg] = useState("");
  const [snackbarColor, setSnackbarColor] = useState("");
  const [orgNameLoad, setOrgNameLoad] = useState(false);

  useEffect(() => {
    if (isUpdate && updateData && orgNameLoad) {
      prefillForm(updateData);
    }
  }, [isUpdate, orgNameLoad]);

  useEffect(() => {
    getAllOrg();
  }, []);
  const getAllOrg = async () => {
    setOrgName(await GetAllOrg());
    setOrgNameLoad(true);
  };

  const prefillForm = (data) => {
    const form = formRef.current;

    form["first_name"].value = data.first_name || "";
    form["last_name"].value = data.last_name || "";
    form["email_id"].value = data.email_id || "";
    setSelectedPosition(
      data.role.id ? radioArray.find((r) => r.id === data.role.id)?.value : null
    );
    // console.log("datatatata", data.organisations);
    const selectedOrgIds = data.organisations.map((org) => org.id); // Extract IDs from organisations array
    setOrgName((prevOrgNames) =>
      prevOrgNames.map((org) => ({
        ...org,
        checked: selectedOrgIds.includes(org.id),
      }))
    );
  };
  // console.log("ORGNAME", OrgName);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true)
    const formData = new FormData(formRef.current);
    const selectedRadio = formRef.current.querySelector(
      'input[name="role_id"]:checked'
    );
    const selectedOrgNames = OrgName.filter((org) => org.checked).map(
      (org) => org.id
    );

    // console.log("SELECTED ORG NAME", selectedOrgNames);
    const data = {
      ...Object.fromEntries(formData),
      org_array: selectedOrgNames,
      role_id: Number(selectedRadio?.id),
    };

    const submitAction = isUpdate ? updateUser : AddUser;

    submitAction({ ...data, ...(isUpdate && { userId: updateData?.id }) }).then(
      (res) => {
        // console.log("response data", res);
        if (res) {
          setAPIMsg(res?.message);
          setOpen(true);
          getAllUser();
          setIsLoading(false)
          res?.success ? setSnackbarColor("green") : setSnackbarColor("red");
        }
        setTimeout(handleClose, 800);
      }
    );
  };

  const handleRadioChange = (event) => {
    setSelectedPosition(event.target.value);
    setOrgName((prevOrgNames) =>
      prevOrgNames.map((org) => ({ ...org, checked: false }))
    );
  };

  const handleCheckboxChange = (event) => {
    const { checked, value } = event.target;

    if (selectedPosition === "director") {
      setOrgName((prevOrgNames) =>
        prevOrgNames.map((org) =>
          org.name === value ? { ...org, checked } : org
        )
      );
    } else {
      setOrgName((prevOrgNames) =>
        prevOrgNames.map((org) =>
          org.name === value ? { ...org, checked } : { ...org, checked: false }
        )
      );
    }
  };

  return (
    <>
      <div className="employee-form">
        <h2>{isUpdate ? "Update User" : "Add User"}</h2>

        <form onSubmit={handleSubmit} ref={formRef}>
          <Grid container rowSpacing={1} columnSpacing={4} className="w-100">
            <Grid item md={6} xs={12}>
              <label>Enter First Name</label>
              <input
                name="first_name"
                type="text"
                placeholder="Enter First Name"
                className="form-control"
              />
            </Grid>

            <Grid item md={6} xs={12}>
              <label>Enter Last Name</label>
              <input
                name="last_name"
                type="text"
                placeholder="Enter Last Name"
                className="form-control"
              />
            </Grid>
            <Grid item md={12} xs={12}>
              <label>Enter Email</label>
              <input
                name="email_id"
                type="email"
                placeholder="Enter Email"
                className="form-control"
              />
            </Grid>
            {radioArray.map((x, index) => (
              <Grid key={index} item md={4} xs={6}>
                <input
                  id={x.id}
                  name="role_id"
                  type="radio"
                  value={x.value}
                  checked={selectedPosition === x.value}
                  onChange={handleRadioChange}
                />
                <label htmlFor={x.id}>{x.name}</label>
              </Grid>
            ))}
            {selectedPosition && (
              <Grid item xs={12}>
                <div
                  className="border"
                  style={{ display: "flex", flexWrap: "wrap" }}
                >
                  {OrgName?.map((x, index) => (
                    <Grid key={index} item md={4} sm={4} xs={6}>
                      <input
                        id={x.value}
                        name="org_array"
                        type="checkbox"
                        value={x.name}
                        checked={x.checked}
                        onChange={handleCheckboxChange}
                      />
                      <label htmlFor={x.value}>{x.name}</label>
                    </Grid>
                  ))}
                </div>
              </Grid>
            )}

            <Grid item xs={12} style={{ textAlign: "center" }}>
              <LoadingButton type="submit" className="btn btn-primary my-10" loading={isLoading}
                loadingPosition="end" variant="contained">
                {isUpdate ? "Update" : "Submit"}
              </LoadingButton>
            </Grid>
          </Grid>
          <SnackbarMsg
            apiMsg={apiMsg}
            open={open}
            color={snackbarColor}
          ></SnackbarMsg>
        </form>
      </div>
    </>

  );
};

export default AddEmployee;
