import React, { useContext, useEffect, useState } from "react";
import {
  GetUsers,
  RefusesNotes,
  ResolvesplitBill,
  getSplitbillData,
} from "../../services/Currency.service";
import "./DirectorRefusedInvoices.css";
import Spinner from "../../components/Spinner/Spinner";
import { Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import GlobalContext from "../TimesheetContext/GlobalContext";
import RefusalDialogForm from "../RefusalDialogForm/RefusalDialogForm";
import LoadingButton from "@mui/lab/LoadingButton";
import { minWidth } from "@mui/system";

const DirectorRefusedInvoices = ({ user }) => {
  const [splitbill, setSplitbill] = useState([]);
  const [users, setUsers] = useState([]);
  const [cuAllCom, setCUAllCom] = useState([]);
  const [refuse, setRefuse] = useState([]);
  const [remark, setRemark] = useState([]);
  const [remarksvisible, setRemarksvisible] = useState(false);
  const [selectedRemark, setSelectedRemark] = useState(-1);
  const [isLoading, setIsLoading] = useState(false);
  const [refusedIndex, setRefusedIndex] = useState("")
  const [isResolved, setIsResolved] = useState(false);
  const [splitBillResp, setSplitBillResp] = useState(null)
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiMsg, setApiMsg] = useState(null)
  const { openTimeSheetModal, setOpenTimeSheetModal } = useContext(GlobalContext);

  const fetchrefusalnotes = async () => {
    const notes = await RefusesNotes();
    setRefuse(notes);
  };

  const tabelCellStyled = {
    tabelCell: {
      fontWeight: 500
    }
  }

  useEffect(() => {
    fetchrefusalnotes();
  }, [refuse, remark]);

  const handleRemarks = (index) => {
    const r = refuse;
    setSelectedRemark(index);
    console.log("refuse", r)
    // console.log(splitbill[index].invoice_no);
    const currNote = r?.filter((elem, idx) => {
      console.log("currNote", elem?.outflow_bill?.invoice_no, splitbill[index]?.invoice_no);
      return elem?.outflow_bill?.invoice_no === splitbill[index]?.invoice_no;
    });
    // console.log("xxxxxx", currNote);
    console.log("rem us", remark);
    // setRemark({
    //   dirname: currNote[z].user.first_name + " " + currNote[z].user.last_name,
    //   reason: currNote[z].note,
    // });
    setRemark(currNote);
    setRemarksvisible(!remarksvisible);
    // navigate2("/home/directorrefusedinvoices");
  };

  const fetchsplitBill = async () => {
    // let bills = await getSplitbillData();
    // setSplitbill(bills);

    setIsLoading(true);
    let bills = await getSplitbillData();
    if (bills) {
      setSplitbill(bills);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchsplitBill().then(() => getCurrentUsersCompany());
  }, [users, isResolved]);

  useEffect(() => {
    getAllUsers();
  }, []);

  const getAllUsers = async () => {
    setUsers(await GetUsers());
  };

  const getCurrentUsersCompany = () => {
    let [currentUserAllCompanies = []] = users
      .filter((e) => e.email_id === user.email)
      .map((e) => e.organisations.map((e) => e.name));

    setCUAllCom([...currentUserAllCompanies]);
  };

  const handleView = (orgSplit) => {
    let flag = false;

    for (let i = 0; i < orgSplit.length; i++) {
      if (
        orgSplit[i].dir_approval_status === "DR" &&
        cuAllCom.includes(orgSplit[i].organisation.name)
      ) {
        flag = true;
        break;
      }
    }
    return flag;
  };

  const refusedObjData = {
    dirid: user?.id,
    del_splitbill_id: splitbill[refusedIndex]?.id,
    isRejected: splitbill[refusedIndex]?.bill?.isRejectedFirstTime,
  }

  const handleChat = () => {
    setOpenTimeSheetModal(true);
  };

  const handleResolve = async (index) => {
    setIsResolved(true)
    const USERS = users;
    let company = [];
    for (let i = 0; i < USERS.length; i++) {
      if (user.email === USERS[i].email_id) {
        company = USERS[i].organisations;
        // console.log(company);
      }
    }
    const company_id = [];
    const company_name = [];
    for (let j = 0; j < company.length; j++) {
      for (let k = 0; k < splitbill[index].org_splits.length; k++) {
        if (
          company[j].name === splitbill[index].org_splits[k].organisation.name
        ) {
          company_id.push(splitbill[index].org_splits[k].id);
          company_name.push(splitbill[index].org_splits[k].organisation.name);
        }
      }
    }

    // console.log("abc ", company_name);
    for (let z = 0; z < company_id.length; z++) {
      console.log(splitbill[index].id, company_id[z]);
      const result = await ResolvesplitBill(splitbill[index].id, company_id[z]);
      setSplitBillResp(result);
    }

    if (splitBillResp) {
      setOpenSnackbar(true)
      setIsResolved(false)
      setApiMsg(splitBillResp)
    }
    setTimeout(() => setOpenSnackbar(false), [1500])

    // alert("Resolved successfuly")
    // await ResolvesplitBill(splitbill[index].id);
    // navigate3("/home/outflowbill");
  };

  // console.log("splibillrespo", splitBillResp);
  // console.log("snackbar val", openSnackbar);

  return (
    <div>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {splitbill.length > 0 ? splitbill?.map((element, index) => {
            const backgroundColor = index % 2 === 0 ? '#F8F8F8' : 'white'
            return handleView(element.org_splits) ? (
              <Card key={index} sx={{ margin: "1rem 0" }} style={{ backgroundColor }}>
                <div>
                  <div className="dir_bill_apprvd_data">
                    <div className="dir_bill_apprvd_data_div1">
                      <p className="pdfLogo-img">
                        <img
                          src="https://cdn.iconscout.com/icon/free/png-256/free-pdf-file-20-504245.png"
                          alt=""
                        />
                      </p>
                      <p className="outflow-invoice-number">
                        {element.bill.invoice_no}
                      </p>
                    </div>
                    <div className="dir_bill_apprvd_data_div">
                      <div className="dir_bill_apprvd_data_div_field">
                        <div className="field_div_data">
                          <p className="tags">From : </p>
                          <p className="tags1">{element.bill.vendor.name}</p>
                        </div>
                        <div className="field_div_data">
                          <p className="tags">Rule :</p>
                          <p className="tags1">{element.rule}</p>
                        </div>
                      </div>
                      <div className="dir_bill_apprvd_data_div_field">
                        <div className="field_div_data">
                          <p className="tags">To :</p>
                          <p className="tags1">{element.bill.organisation.name}</p>
                        </div>
                        <div className="field_div_data">
                          <p className="tags">GL Code :</p>
                          <p className="tags1">{element.gl_code_rec}</p>
                        </div>
                      </div>
                      <div className="dir_bill_apprvd_data_div_field">
                        <div className="field_div_data">
                          <p className="tags">Sub-Total :</p>
                          <p className="tags1">{element.bill.currency}</p>
                          <p className="tags1">${element.bill.subtotal}</p>
                        </div>
                        <div className="field_div_data">
                          <p className="tags">PO Amount :</p>
                          <p className="tags1">${element.bill.po_amount}</p>
                        </div>
                      </div>
                      <div className="dir_bill_apprvd_data_div_field">
                        <div className="field_div_data">
                          <p className="tags">Total :</p>
                          <p className="tags1">{element.bill.currency}</p>
                          <p className="tags1">${element.bill.total_amt}</p>
                        </div>
                        <div className="field_div_data">
                          <p className="tags">GST :</p>
                          <p className="tags1">{element.bill.currency}</p>
                          <p className="tags1">${element.bill.total_gst}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <TableContainer style={{ padding: "0 10px 10px 0 ", width: "100%" }}>
                    <Table size="small" sx={{ '& .MuiTableCell-root': { fontSize: '10px', fontWeight: "600" } }}>
                      <TableHead>
                        <TableRow>
                          <TableCell>Invoice No.</TableCell>
                          <TableCell>Company</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Split%</TableCell>
                          <TableCell>GST</TableCell>
                          <TableCell>Total(Excluding GST.)</TableCell>
                          <TableCell>Local(NZD)</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {element.org_splits.map((orgSplit, idx) => {
                          return (
                            <TableRow key={idx}>
                              <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.split_invoice_no}</TableCell>
                              <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.organisation.name}</TableCell>
                              <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.description}</TableCell>
                              <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.split_per}%</TableCell>
                              {/* <td>
                              {orgSplit.gst ? <span>&#10003;</span> : <></>}
                            </td> */}

                              <TableCell style={tabelCellStyled.tabelCell} className="td-data">
                                {orgSplit.gst ? (
                                  <span>&#10003;</span>
                                ) : (
                                  // <>&#xd7;</>
                                  <>&#88;</>
                                )}
                                &nbsp;&nbsp;&nbsp;
                                <span className="span-data">
                                  {orgSplit.organisation.currency}
                                  {orgSplit.gst_amount}
                                </span>
                              </TableCell>
                              <TableCell style={tabelCellStyled.tabelCell}>
                                {orgSplit.organisation.currency}
                                {orgSplit.payOrganizationAmount}
                              </TableCell>
                              <TableCell style={tabelCellStyled.tabelCell}>{orgSplit.local_nzd}</TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div /* className="remarks-container" */>
                  <div className="remarks1">
                    <button
                      onClick={() => {
                        handleRemarks(index);
                      }}
                    >
                      Remarks
                    </button>
                  </div>
                  {remarksvisible && selectedRemark === index ? (
                    <>
                      {remark?.map((elem, id) => {
                        return (
                          <div className="remarks2">
                            <span>
                              {elem?.user?.first_name}&nbsp;{elem?.user?.last_name}-
                            </span>
                            <>{elem?.note}</>
                          </div>
                        );
                      })}
                    </>
                  ) : null}
                </div>
                <div className="director-chat-button">
                  <Button size="small" variant="outlined" sx={{ fontSize: "0.6rem" }}
                    onClick={() => {
                      handleChat(index);
                      setRefusedIndex(index)
                    }}
                  >
                    Add Remarks
                  </Button>
                  <Button size="small" variant="contained" sx={{ fontSize: "0.6rem" }}
                    onClick={() => {
                      handleResolve(index);
                    }}
                  >
                    Resolve
                  </Button>
                </div>
              </Card>
            ) : null;
          }) : <div style={{ textAlign: "center", fontWeight: "500" }}>No refused bill available</div>}
          {openTimeSheetModal && (
            <RefusalDialogForm
              open={openTimeSheetModal}
              setOpenTimeSheetModal={setOpenTimeSheetModal}
              refusedObjData={refusedObjData}
            />
          )}
        </>
      )}
    </div>
  );
};

export default DirectorRefusedInvoices;
